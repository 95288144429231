import { Card } from "react-bootstrap"

export default function Footer(props){
    return(
        <Card>
            <Card.Footer>
               <strong>
                Tool Flow Copyright &copy; 2023
            </strong> 
            </Card.Footer>
        </Card>
        
    )
}

